var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      ref: "materialListItem",
      staticClass: "dp-pro-recipe-material-list-item",
    },
    [
      _c("div", [
        _c(
          "span",
          { ref: "name", staticClass: "dp-pro-recipe-material-list-item-name" },
          [_vm._v(_vm._s(_vm.content.material))]
        ),
      ]),
      _c("div", { staticClass: "dp-pro-recipe-material-list-item__content" }, [
        _c("span", {
          ref: "separate",
          staticClass: "dp-pro-recipe-material-list-item-separate",
        }),
        _c(
          "span",
          {
            ref: "quantity",
            staticClass: "dp-pro-recipe-material-list-item-quantity",
          },
          [_vm._v(_vm._s(_vm.content.quantity))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }