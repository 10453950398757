<template>
  <li ref="materialListItem" class="dp-pro-recipe-material-list-item">
    <div>
      <span ref="name" class="dp-pro-recipe-material-list-item-name">{{
        content.material
      }}</span>
    </div>
    <div class="dp-pro-recipe-material-list-item__content">
      <span ref="separate" class="dp-pro-recipe-material-list-item-separate" />
      <span ref="quantity" class="dp-pro-recipe-material-list-item-quantity">{{
        content.quantity
      }}</span>
    </div>
  </li>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted
} from '@vue/composition-api';

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: null
    }
  },

  setup: () => {
    /**
     * ① 材料名と分量で改行する基準
     * - 材料名と分量併せて横幅70％まで許容する（破線分の30％を確保する）
     * - 破線分の30％を確保できない場合は材料名のみで改行し、下行に破線と分量を表示する
     *
     * ② 材料名の文字数が多い（材料名の要素の幅が大きい）場合
     * - 材料名の横幅を100%にする。
     * - 破線と分量は一行として改行する。
     * - 分量は右詰めにする。
     * - 破線は余剰の左端まで埋める。
     *
     * ③ 分量の文字数が多い（分量の要素の幅が大きい）場合
     * - 材料名のみで改行する。
     * - 破線と分量は一行として改行する。
     * - 分量は右詰めにする。
     * - 横幅を70％にする。収まらない場合は改行する。
     * - 左に30％の幅で破線を表示する。
     *
     * @see https://drive.google.com/file/d/1zR06p9MTh5aas-Nr6-PKIGBTgSZNKA6f/view?usp=share_link
     */
    // 項目（行全体）
    const materialListItem = ref(null);
    const materialListItemWidth = computed(
      () => materialListItem.value.clientWidth || 0
    );

    // レシピの材料名
    const name = ref(null);
    const nameWidth = computed(() => name.value.clientWidth || 0);

    // 材料の分量
    const quantity = ref(null);
    const quantityWidth = computed(() => quantity.value.clientWidth || 0);

    // 材料名の文字数が多い（要素の幅が大きい・行の70%を超える）場合
    const isLongMaterialName = computed(
      () =>
        Math.round((nameWidth.value / materialListItemWidth.value) * 100) > 70
    );

    // 分量が長い（要素の幅が大きい・行の70%を超える）場合
    const isLongQuantity = computed(() =>
      Math.round((quantityWidth.value / materialListItemWidth.value) * 100 > 70)
    );

    onMounted(async () => {
      // 一度レンダリングされた要素から値を算出しclassを付与する
      if (!isLongMaterialName.value && !isLongQuantity.value) {
        materialListItem.value.classList.add(
          'dp-pro-recipe-material-list-item--1column'
        );
      }
    });

    return {
      materialListItem,
      name,
      quantity
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-material-list-item {
  position: relative;
  display: grid;
  gap: 0 8px;
  grid-template-columns: repeat(auto-fill, minmax(auto, 100%));
  align-items: start;
  justify-content: space-between;
  overflow: hidden;
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    display: grid;
    gap: 8px;
    align-items: start;
    justify-content: space-between;
    grid-template-columns: minmax(30%, 1fr) auto;
  }

  &--1column {
    grid-template-columns: auto 1fr;
  }
}

.dp-pro-recipe-material-list-item-name {
  display: inline-block; // ブロック要素として幅を取得する
}

.dp-pro-recipe-material-list-item-separate {
  margin-top: 0.7em;
  height: 1px;
  border-bottom: 1px dotted variables.$dpGreyCc;
}

.dp-pro-recipe-material-list-item-quantity {
  text-align: right;
}
</style>
